import * as React from 'react';
import KnowledgeBasePost from '../../models/KnowledgeBasePost';
import Bootstrapper from '../bootstrapper';
import AppData from '../../models/AppData';
import CoreFeatures from '../core_features';
import KnowledgeBase from '../knowledge_base';
import GlobalHeader from '../global_header';

const AppRoot: React.FC = () => {
  const [data, setData] = React.useState(new AppData());

  return (
    <Bootstrapper
      onLoad={data => {
        setData(data);
      }}>
        <GlobalHeader />
        <CoreFeatures features={data.coreFeatures} />
        <KnowledgeBase posts={data.knowledgeBase} />
    </Bootstrapper>
  )
}

export default AppRoot;