import * as React from 'react';
import yamlToJson from 'js-yaml';
import AppData from '../../models/AppData';
import './styles.scss';

interface Props {
  onLoad: (payload: AppData) => void;
  children?: any;
}

/**
 * Loads files required for initing the app.
 * @author Johan Svensson
 */
const Bootstrapper: React.FC<Props> = ({ onLoad, children }) => {

  const [busy, setBusy] = React.useState(true);

  async function load() {
    setBusy(true);

    const knowledgeBaseYaml = await fetch(require('../../assets/knowledge.yml')).then(res => res.text());
    const coreFeaturesYaml = await fetch(require('../../assets/core_features.yml')).then(res => res.text());

    onLoad({
      knowledgeBase: yamlToJson.safeLoad(knowledgeBaseYaml),
      coreFeatures: yamlToJson.safeLoad(coreFeaturesYaml)
    });
    setBusy(false);
  }

  React.useEffect(() => {
    load();
  }, []);

  if (busy) {
    return null;
  }

  return (
    <div className="bootstrapper loaded">{children}</div>
  );
}

export default Bootstrapper;