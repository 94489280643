import * as React from 'react';

/**
 * Renders a placeholder for when no matches were found.
 * @author Johan Svensson
 */
const Placeholder: React.FC = () => (
  <p className="kb-placeholder">
    Hoppsan! Inga inlägg hittades
  </p>
)

export default Placeholder;