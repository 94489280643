import * as React from 'react';
import cls from 'classnames';
import CoreFeatureModel from '../../models/CoreFeature';

const icons: any = {
  'statistics': require('../../assets/ic/ic_charts.svg'),
  'expenses': require('../../assets/ic/ic_expenses.svg'),
  'orders': require('../../assets/ic/ic_orders.svg'),
  'stock': require('../../assets/ic/ic_stock.svg'),
  'users': require('../../assets/ic/ic_users.svg'),
  'calendar': require('../../assets/ic/ic_calendar.svg')
};

interface Props {
  feature: CoreFeatureModel;
}

/**
 * Renders a single core feature.
 * @author Johan Svensson
 */
const CoreFeature: React.FC<Props> = ({ feature }) => {
  const urlHash = document.location.hash.replace('#', '');

  const autoOpen = urlHash == feature.id;

  const [open, setOpen] = React.useState(false);
  const didAutoOpen = React.useRef(false);
  const contentOpenHeight = React.useRef(0);

  const iconSrc = icons[feature.id];

  return (
    <article id={feature.id} className={cls({ "cf-post": true, "open": open })}>
      <header className="header" role="button" onClick={() => setOpen(!open)}>
        <main>
          {iconSrc ? (
            <div className="icon-wrapper">
              <img className="icon" src={iconSrc} alt={feature.header} />
            </div>
          ) : null}
          {feature.header}
        </main>

        <img className="chevron" alt="" src={require('../../assets/ic/ic_chevron_36.svg')} />
      </header>
      <div className="content" style={{ height: open ? contentOpenHeight.current : 32 }}>
        <div ref={e => {
          if (e) {
            contentOpenHeight.current = e.getBoundingClientRect().height;

            if (autoOpen && !didAutoOpen.current) {
              setOpen(true);
              didAutoOpen.current = true;
            }
          }
        }}>
          {feature.content.split('\n\n').map(paragraph => <p>{paragraph}</p>)}
        </div>
      </div>
    </article>
  ) as any
}

export default CoreFeature;