import * as React from 'react';
import './styles.scss';

/**
 * App header.
 * @author Johan Svensson
 */
const GlobalHeader: React.FC = () => (
  <header className="global-header">
    <aside></aside>
    <h1>
      Få hjälp med&nbsp;
      <span className="logo">
        <img
          src={require('../../assets/salesyslogo.jpg')}
          alt="SaleSys logo"
          onDragStart={e => e.preventDefault()} /> Sale<span className="color-primary">Sys</span>
      </span>
    </h1>

    <aside>
      <a className="normal button" href="https://app.salesys.se">
        Till appen
      </a>
    </aside>
  </header>
);

export default GlobalHeader;