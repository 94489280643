import React from 'react';
import ReactDOM from 'react-dom';
import './styles/base.scss';
import AppRoot from './components/app_root';
import GlobalFooter from './components/global_footer';

ReactDOM.render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>,
  document.getElementById('app')
);

ReactDOM.render(
  <GlobalFooter />,
  document.getElementById('footer-container')
);