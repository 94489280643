import KnowledgeBasePost from "./KnowledgeBasePost";
import CoreFeature from "./CoreFeature";

/**
 * App data to be bootstrapped.
 * @author Johan Svensson
 */
export default class AppData {
  knowledgeBase: KnowledgeBasePost[] = [];
  coreFeatures: CoreFeature[] = [];
}