import * as React from 'react';
import './styles.scss';

/**
 * A global footer with copyright and contact info.
 * This is the same footer which appears on the landing page.
 * @author Johan Svensson
 */
const GlobalFooter: React.FC = () => (
  <div className="global-footer-abs-container">
    <footer className="global-footer">
      <div className="content">
        <p className="copyright">
          © 2020 SaleSys AB – Box 1030 85111 Sundsvall
        </p>

        <ul className="contact">
          <li>
            <img className="icon" src={require('../../assets/ic/ic_mail_36.svg')} />
            <p className="value">info@salesys.se</p>
          </li>
          <li>
            <img className="icon" src={require('../../assets/ic/ic_phone_36.svg')} />
            <p className="value">073-439 32 80</p>
          </li>
        </ul>
      </div>
    </footer>
  </div>
);

export default GlobalFooter;