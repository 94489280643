import * as React from 'react';
import KnowledgeBasePostModel from '../../models/KnowledgeBasePost';
import KnowledgeBasePostComponent from './KnowledgeBasePost';
import SearchField from '../search_field';
import { compareTwoStrings } from 'string-similarity';
import Placeholder from './Placeholder';
import './styles.scss';

interface Props {
  posts: KnowledgeBasePostModel[];
}

/**
 * Renders a list of knowledge base posts.
 * @author Johan Svensson
 */
const KnowledgeBase: React.FC<Props> = ({ posts }) => {
  const [query, setQuery] = React.useState('');
  const setQueryTimeoutRef = React.useRef<any>(null);

  function debounceSetQuery(query: string) {
    clearTimeout(setQueryTimeoutRef.current);
    setQueryTimeoutRef.current = setTimeout(() => setQuery(query), 500);
  }

  const hasQuery = (query || '').length > 1;

  const queriedPosts: { post: KnowledgeBasePostModel, relevance: number }[] =
    hasQuery ? posts.map(post => ({
      post,
      relevance: compareTwoStrings(query, post.header)
    })).filter(({ relevance }) => relevance > 0.085) : [];

  queriedPosts.sort((a, b) => b.relevance - a.relevance);

  const resolvedPosts = !hasQuery ? posts : queriedPosts.map(({ post }) => post);

  return (
    <div className="knowledge-base">
      <SearchField
        onChange={e => debounceSetQuery(e.currentTarget.value)} />

      {
        resolvedPosts.length ? (
          resolvedPosts.map(post => (
            <KnowledgeBasePostComponent key={post.header} post={post} />
          ))
        ) : (
            <Placeholder />
          )
      }
    </div>
  );
}

export default KnowledgeBase;