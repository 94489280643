import * as React from 'react';
import CoreFeatureModel from '../../models/CoreFeature';
import CoreFeatureComponent from './CoreFeature';
import './styles.scss';

interface Props {
  features: CoreFeatureModel[];
}

/**
 * Renders the app core features ingress.
 * @author Johan Svensson
 */
const CoreFeatures: React.FC<Props> = ({ features }) => {
  return (
    <div className="core-features">
      {
        features.map(feature => (
          <CoreFeatureComponent key={feature.header} feature={feature} />
        ))
      }
      {
        features.length % 2 != 0 ? (
          //  Use a placeholder to prevent the last item stretching.
          <div className="cf-placeholder"></div>
        ) : null
      }
    </div>
  );
}

export default CoreFeatures;