import * as React from 'react';
import cls from 'classnames';
import KnowledgeBasePostModel from '../../models/KnowledgeBasePost';
import { Collapse } from 'react-collapse';

interface Props {
  post: KnowledgeBasePostModel;
}

/**
 * Renders a single knowledge base post.
 * @author Johan Svensson
 */
const KnowledgeBasePost: React.FC<Props> = ({ post }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <article className={cls({ "kb-post": true, "open": open })}>
      <header className="header" role="button" onClick={() => setOpen(!open)}>
        {post.header}
      </header>
      <Collapse className="content" isOpened={open}>
        <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
      </Collapse>
    </article>
  ) as any
}

export default KnowledgeBasePost;