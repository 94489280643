import * as React from 'react';
import cls from 'classnames';
import './styles.scss';

interface Props {
  placeholder?: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * A basic search field.
 * @author Johan Svensson
 */
const SearchField: React.FC<Props> = ({ onChange, placeholder }) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <div className={cls({ "search-field": true, "focus": focused })}>
      <img alt="Sök" className="icon" src={require('../../assets/ic/ic_search.svg')} />
      <input
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)} />
    </div>
  )
}

SearchField.defaultProps = {
  placeholder: 'Hur kan vi hjälpa dig?'
};

export default SearchField;